<template>
  <error-assign-dashboard />
</template>

<script>
const ErrorAssignDashboard = () => import('@/components/Car/ErrorAssignDashboard')

export default {
  name: 'ErrorAssignView',
  components: {
    ErrorAssignDashboard
  }
}
</script>
